import { Scanner } from '@yudiel/react-qr-scanner';
import './App.css';
import { useEffect, useState } from 'react';

const API_URL = 'https://coffeestack-api.dylanhedger1901.workers.dev/';
// const API_URL = 'http://127.0.0.1:8787/';

const Card = (props) => {
	return (
		<div className="card" style={props.style}>
			{props.children}
		</div>
	);
};

const Store = (props) => {
	const store = props.store;

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [mobile, setMobile] = useState('');

	const [showForm, setShowForm] = useState(true);
	const [loading, setLoading] = useState(false);

	const [addToWallet, setAddToWallet] = useState('');

	function joinCafe() {
		if (!firstName || !mobile) {
			alert('First Name and Mobile are required');
			alert(firstName);
			alert(mobile);
			return;
		}
		setLoading(true);
		setShowForm(false);

		fetch(API_URL + store.id, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ name: `${firstName}${lastName ? ` ${lastName}` : ''}`, mobile }),
		})
			.then((response) => (response.ok ? response.json() : Promise.reject(response)))
			.then((data) => {
				setAddToWallet(`<g:savetoandroidpay textsize="large" jwt="${data.saveJWT}" />`);
			})
			.catch((err) => {
				alert('Error joining cafe');
				setShowForm(true);
			})
			.finally(() => {
				const i = setInterval(() => {
					const frame = document.querySelector('iframe');
					if (frame) {
						clearInterval(i);
						frame.onload = () => {
							setLoading(false);
						};
					}
				}, 100);
			});
	}

	useEffect(() => {
		const scriptUrl = 'https://apis.google.com/js/platform.js';
		const script = document.createElement('script');
		script.src = scriptUrl;
		script.async = true;
		document.body.appendChild(script);
	}, [addToWallet]);

	return (
		<>
			<Card style={{ marginTop: '4vh' }}>
				<div className="logo-container">
					{store.logo_url ? <img style={{ width: '100%' }} src={store.logo_url} className="logo" alt="logo" /> : <h1>{store.name}</h1>}
				</div>

				<div class="location">
					<p>{store.location}</p>
				</div>
				<div class="desc">
					<p>{store.description}</p>
				</div>
			</Card>
			{loading && (
				<Card>
					<p style={{ textAlign: 'center' }}>Loading...</p>
				</Card>
			)}
			<div className="container" id="form" style={showForm ? {} : { display: 'none' }}>
				<Card>
					<label for="firstName">First Name *</label>
					<input id="firstName" type="text" required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
					<label for="lastName">Last Name</label>
					<input id="lastName" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
					<label for="mobile">Mobile *</label>
					<input id="mobile" type="tel" required value={mobile} onChange={(e) => setMobile(e.target.value)} />
				</Card>
				<Card style={{ textAlign: 'center' }}>
					<button type="submit" onClick={() => joinCafe()}>
						Join {store.name}
					</button>
					<p style={{ fontSize: '0.8rem', marginTop: '-1px' }}>
						By clicking Join, you agree to our <a href="#">Terms</a>, <a href="#">Privacy Policy</a> and <a href="#">Cookie Policy</a>.
					</p>
					<p style={{ fontSize: '0.8rem', marginTop: '8px' }}>You may receive SMS notifications from us and can opt out at any time.</p>
				</Card>
			</div>
			<div
				className="container"
				id="addToWallet"
				dangerouslySetInnerHTML={{ __html: addToWallet }}
				style={showForm ? { display: 'none' } : {}}
			></div>
		</>
	);
};

const stampCard = async (store, code) => {
	await fetch(API_URL + store + '/' + code, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	});
	document.location.reload();
};

function App() {
	const url = new URL(window.location.href);
	const path = url.pathname.split('/').filter((p) => p);

	const [loading, setLoading] = useState(true);
	const [store, setStore] = useState('');

	const fetchData = () => {
		setLoading(true);
		fetch(API_URL + path[0])
			.then((response) => (response.ok ? response.json() : Promise.reject(response)))
			.then((data) => {
				const storeData = {
					id: data.id,
				};

				if (data.name) storeData.name = data.name;
				if (data.description) storeData.description = data.description;
				if (data.location) storeData.location = data.location;
				if (data.logo_url) storeData.logo_url = data.logo_url;

				setStore(storeData);

				setLoading(false);
			});
	};

	useEffect(() => {
		if (path.length > 0) fetchData();
		else setLoading(false);
	}, []);

	return (
		<div className="App">
			<div className="container">
				{loading ? (
					<Card style={{ marginTop: '4vh' }}>
						<p style={{ textAlign: 'center' }}>Loading...</p>
					</Card>
				) : store != '' && path.length == 1 ? (
					<Store store={store} />
				) : store != '' && path[1] == 'scan' ? (
					<Card style={{ marginTop: '4vh' }}>
						<h1 style={{ textAlign: 'center' }}>Scaning Mode</h1>
						<Scanner
							onScan={(result) => {
								stampCard(path[0], result[0].rawValue);
								alert('Stamping Card');
							}}
						/>
					</Card>
				) : (
					<Card style={{ marginTop: '4vh' }}>
						<h1 style={{ textAlign: 'center' }}>404 Not Found</h1>
					</Card>
				)}
			</div>
			<div class="footer">
				<p>{store.name ? `Copyright © 2024 ${store.name}` : ''}</p>
				<p>Copyright © 2024 Coffee Stack</p>
			</div>
		</div>
	);
}

export default App;
